import "./App.css";
import React, { useEffect } from "react";

const App: React.FC = () => {

    // Function to handle scrolling
    // Function to handle scrolling
    const scrollToNextVideo = () => {
        // Update the selector to match the new class name
        const nextVideo = document.querySelector(".video-bg-next");
        if (nextVideo) {
            const targetPosition = nextVideo.getBoundingClientRect().top + window.scrollY;
            const startPosition = window.scrollY;
            const distance = targetPosition - startPosition;
            const duration = 1500;
            let startTime: number | null = null;

            const animation = (currentTime: number) => {
                if (startTime === null) startTime = currentTime;
                const timeElapsed = currentTime - startTime;
                const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);

                window.scrollTo(0, run);

                if (timeElapsed < duration) requestAnimationFrame(animation);
            };

            // Easing function for smooth animation
            const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
            };

            requestAnimationFrame(animation);
        }
    };


    useEffect(() => {
        const cursorRing = document.querySelector(".cursor-ring");

        // Check if the cursor ring exists before trying to set its position
        if (cursorRing) {
            document.addEventListener("mousemove", (e) => {
                // Ensure that cursorRing is not null before accessing its style
                if (cursorRing instanceof HTMLElement) {
                    // Account for the scroll position
                    const scrollY = window.scrollY; // Get the current scroll position
                    const scrollX = window.scrollX;

                    // Set the ring position, adjusting for the scroll
                    cursorRing.style.top = `${e.clientY + scrollY}px`; // Add scrollY to the Y coordinate
                    cursorRing.style.left = `${e.clientX + scrollX}px`; // Add scrollX to the X coordinate
                }
            });
        }

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("mousemove", () => { });
        };
    }, []);

    return (
        <div>
            <div className="cursor-ring"></div>
            {/* Background Video */}
            <video
                className="video-bg"
                src="https://videos.pexels.com/video-files/3192198/3192198-uhd_3840_2160_25fps.mp4"
                autoPlay
                loop
                muted
            ></video>

            {/* Overlay */}
            <div className="overlay"></div>

            {/* Hero Section */}
            <section className="hero-title">
                <h1>BEAUMONT FC ACADEMY</h1>
            </section>
            <section className="hero">
                <h1>Developing the Next Generation of Soccer Stars.</h1>
                <button onClick={scrollToNextVideo}>See More</button>
            </section>

            {/* Second Video */}
            <video
                className="video-bg-next"
                src="https://videos.pexels.com/video-files/2249402/2249402-uhd_3840_2160_24fps.mp4"
                autoPlay
                loop
                muted
            ></video>
            <section className="hero section-two">
                <h1>Three Coaches.</h1>
                <h1>Over 60 Years of</h1>
                <h1>Accumulated Experience</h1>
            </section>

            {/* Third Video */}
            <video
                className="video-bg-next"
                src="https://videos.pexels.com/video-files/2938865/2938865-uhd_4096_2160_24fps.mp4"
                autoPlay
                loop
                muted
            ></video>
            <section className="hero section-three">
                <h1>Local to the</h1>
                <h1>Inland Empire.</h1>
            </section>

        </div>
    );
};

export default App;
